/* If you need to add @import statements, do so up here */

@import 'jit-refresh.css'; /* triggers frontend rebuilds */

/* libre-caslon-text-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/libre-caslon-text-v5-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* libre-caslon-text-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Caslon Text';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/libre-caslon-text-v5-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* libre-caslon-text-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/libre-caslon-text-v5-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.container-x-padding {
  @apply px-4 sm:px-6 lg:px-8;
}

.main-padding {
  @apply mt-8 md:mt-16;
}

.text-link {
  @apply text-dlp-blue hover:underline hover:text-dlp-dark-blue decoration-1 underline-offset-2 decoration-dlp-blue hover:decoration-dlp-dark-blue;
  @apply group-hover:underline group-hover:text-dlp-dark-blue group-hover:decoration-dlp-dark-blue;
}

.prose {
  @apply text-gray-900 prose-a:font-normal prose-a:text-dlp-blue prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-dlp-blue;
  @apply hover:prose-a:text-dlp-dark-blue hover:prose-a:decoration-dlp-dark-blue;
  @apply prose-headings:font-serif prose-headings:font-normal prose-headings:mb-4;
}

.prose blockquote p:before,
.prose blockquote p:after {
  content: '';
}

.prose hr {
  @apply h-auto mt-6 mb-4 text-center border-none;
}
.prose hr:before {
  content: '* * *';
}
